import { object } from 'prop-types';
import { getCurrentRole } from 'src/services/Roles/Merchants';

const ALLOWED = '1';
const DENIED = '0';
const NOT_SET = '';

export const AUTH_KEY = {
  name: 'name',
  org: 'org',
  booth_picker: 'booth_picker',
  dashboard: 'dashboard',
  summary: 'summary',
  transactions: 'transactions',
  transactions_detail: 'transactions_detail',
  daily_recap: 'daily_recap',
  daily_recap_details: 'daily_recap_details',
  voucher_usage: 'voucher_usage',
  voucher_usage_details: 'voucher_usage_details',
  reviews: 'reviews',
  reviews_detail: 'reviews_detail',
  branch_analytics: 'branch_analytics',
  booths: 'booths',
  booth_actions: 'booth_actions',
  merchant: 'merchant',
  merchant_actions: 'merchant_actions',
  cash_and_bank: 'cash_and_bank',
  cash_and_bank_details: 'cash_and_bank_details',
  account_chart: 'account_chart',
  account_chart_details: 'account_chart_details',
  accounting_transactions: 'accounting_transactions',
  accounting_transactions_details: 'accounting_transactions_details',
  frames: 'frames',
  frames_assignment: 'frames_assignment',
  incident_reports: 'incident_reports',
  incident_reports_action: 'incident_reports_action',
  vouchers: 'vouchers',
  vouchers_action: 'vouchers_action',
  export_reports: 'export_reports',
  role_setting: 'role_setting',
  refunds: 'refunds',
  refund_actions: 'refund_actions'
};

class Authentication {
  role = {
    name: '',
    org: '',
    booth_picker: '',
    dashboard: '',
    summary: '',
    transactions: '',
    transactions_detail: '',
    daily_recap: '',
    daily_recap_details: '',
    voucher_usage: '',
    voucher_usage_details: '',
    reviews: '',
    reviews_detail: '',
    branch_analytics: '',
    booths: '',
    booth_actions: '',
    merchant: '',
    merchant_actions: '',
    cash_and_bank: '',
    cash_and_bank_details: '',
    account_chart: '',
    account_chart_details: '',
    accounting_transactions: '',
    accounting_transactions_details: '',
    frames: '',
    frames_assignment: '',
    incident_reports: '',
    incident_reports_action: '',
    vouchers: '',
    vouchers_action: '',
    export_reports: '',
    role_setting: '',
    refunds: '',
    refund_actions: ''
  };

  async fetchPermissions() {
    await getCurrentRole().then((response) => {
      if (response.success == 1) {
        if (response.userDeletedAt != null) {
        } else {
          Object.keys(AUTH_KEY).forEach((attr) => {
            this.role[AUTH_KEY[attr]] = response.data[AUTH_KEY[attr]];
          });
        }
      }
    });
  }

  isEmpty() {
    return this.role.name == '';
  }

  isAllowed(permission) {
    return permission == ALLOWED;
  }

  isDenied(permission) {
    return permission == DENIED || permission == NOT_SET;
  }
}

export default Authentication;
